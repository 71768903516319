import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from 'react-cookie'
import { useParams } from "react-router-dom";
interface Props {
    children: React.ReactNode;
}

const defaultCookiesConsent ={
        necessary: true,
        preferences: false,
        statistics: false,
        thirdParty: false,
        session: false,
    };
    interface CookieTypes {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        thirdParty: boolean;
        session: boolean;
    }
const CookiesContext = createContext<{
        necessary: boolean,
        preferences: boolean,
        statistics: boolean,
        thirdParty: boolean,
        session: boolean,
        acceptCookies: (cookies: CookieTypes)  => void,
        acceptAllCookies: ()  => void,
        hasCookie: boolean|undefined,
        isBannerVisible: boolean,
        setBannerVisible: (visible: boolean) => void,
}>({
    ...defaultCookiesConsent,
    acceptCookies:(cookies: CookieTypes) => {},
    acceptAllCookies: ()  => {},
    hasCookie: false,
    isBannerVisible: true,
    setBannerVisible: (visible: boolean) => {}
});

const ConsentCookiesProvider: React.FC<Props> = ({ children }) => {
  
    const [cookies, setCookie] = useCookies(['COOKIES_CONSENT']);
    const [hasCookie, setHasCookie] = useState<boolean|undefined>()
    const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true)
    const [cookiesInfo, setCookiesInfo] = useState<CookieTypes>({
        necessary: true,
        preferences: false,
        statistics: false,
        thirdParty: false,
        session: false,
    });
    const processUrlCookiesAcceptance = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const cookiesConfirmed = urlParams.get('cookiesConfirmed') ?? false
        if (cookiesConfirmed=== 'true') {
            acceptAllCookies()
            return true
        }
        return false
    }

    useEffect(() => {
        if (cookies.COOKIES_CONSENT) {
            setHasCookie(true)
            setIsBannerVisible(false)
            setCookiesInfo(cookies.COOKIES_CONSENT)
        } else {
            if (!processUrlCookiesAcceptance()) {
                setIsBannerVisible(true)
            setHasCookie(false)}
        }
    }, [cookies])

    

    const acceptCookies = (cookies: CookieTypes): void => {
        setCookie('COOKIES_CONSENT', cookies, {sameSite: 'none', secure: true,expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))})
        setIsBannerVisible(false)
    }
    const acceptAllCookies = (): void => {
        setCookie('COOKIES_CONSENT', {
            necessary: true,
            preferences: true,
            statistics: true,
            thirdParty: true,
            session: true,
        },
            {sameSite: 'none', secure: true, expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))})
            setIsBannerVisible(false)

    }

    return (<CookiesContext.Provider
    value={{
        ...cookiesInfo,
        acceptCookies: acceptCookies,
        acceptAllCookies:  acceptAllCookies,
        hasCookie: hasCookie,
        isBannerVisible: isBannerVisible,
        setBannerVisible: setIsBannerVisible
    }}>
    {children}
    </CookiesContext.Provider>)
}
const useCookiesConsent = () => useContext(CookiesContext)

export {CookiesContext, ConsentCookiesProvider, useCookiesConsent};